<template>
  <div class="login-wrapper">
    <div class="card login-form">
      <div class="card-body py-5 px-4">
        <div class="logo mb-5">
          <img
            src="../../assets/logo.svg"
            alt=""
          >
        </div>
        <form
          @submit="checkForm"
        >
          <p v-if="errors.length">
            <b>Verificar o(s) seguinte(s) campo(s):</b>
            <ul>
              <li v-for="error in errors">{{ error }}</li>
            </ul>
          </p>
          <div class="form-group">
            <label for="email">Nome completo</label>
            <input
              id="email"
              type="email"
              class="form-control"
              v-model="form.email"
              aria-describedby="emailHelp"
            >
          </div>
          <div class="form-group">
            <label for="email">E-mail</label>
            <input
              id="email"
              type="email"
              class="form-control"
              v-model="form.email"
              aria-describedby="emailHelp"
            >
          </div>
          <div class="form-group">
            <label for="password">Senha</label>
            <input
              id="password"
              type="password"
              v-model="form.password"
              class="form-control"
            >
          </div>
          <div class="form-group">
            <label for="password">Confirme sua senha</label>
            <input
              id="password"
              type="password"
              v-model="form.password"
              class="form-control"
            >
          </div>
          <button
            type="submit"
            class="btn btn-primary btn-block mb-3"
          >
            Cadastrar
          </button>

          <router-link class="btn btn-block" to="/">
            Cancelar
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>

.<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  margin: 0 10%;
  height: 100%;
  width: 350px;
}

.login-form {
  width: 100%;
  margin: auto;
}

.logo {
  width: 60%;
  margin: 0 auto;

  > img {
    width: 100%;
  }
}
</style>

<script>
export default {
  data() {
    return {
      errors: [],
      form: {},
    };
  },
  methods: {
    checkForm: function (e) {
      if (
        this.form.email &&
        this.form.password
      ) {
        return true;
      }

      this.errors = [];

      if (!this.form.email) {
        this.errors.push("Campo E-mail é obrigatório.");
      }

      if (!this.form.password) {
        this.errors.push("Campo Senha é obrigatório.");
      }

      console.log(this.errors);

      e.preventDefault();
    },
    login() {
      console.log(this.form);
    },
  },
};
</script>
